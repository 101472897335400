<template>
  <div>
    <vx-card :title="`#${initData.header.no_pengajuan}`" class="mb-3" collapse-action :is-refresh="isLoading">
      <div class="vx-row">
        <div class="vx-col">
          <div class="img-container mb-4">
            <img alt="" class="w-32" src="@/assets/svg/share_media.svg"/>
          </div>
        </div>
        <div class="vx-col flex-1">
          <table class="table-info">
            <tr>
              <td class="font-semibold">Tgl. Pengajuan</td>
              <td>: {{ initData.header.tgl_pengajuan }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Tipe Platform</td>
              <td>: {{ initData.header.tipe_platform }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Nama Platform</td>
              <td>: {{ initData.header.nama_platform }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Status Approval</td>
              <td>: <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs text-white rounded-full" :class="['bg-' + initData.header.status_approval_color]">{{ initData.header.status_approval }}</span></td>
            </tr>
            <tr>
              <td class="font-semibold">Status</td>
              <td>: <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs text-white rounded-full" :class="['bg-' + initData.header.status_color]">{{ initData.header.status }}</span></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="vx-row mt-3">
        <div class="vx-col flex flex-wrap space-x-2">
          <vs-button type="filled" color="success" size="small" class="px-3" icon-pack="feather" icon="icon-send" @click="confirmPublish" :disabled="!isCanPublish">Publish</vs-button>
          <vs-button type="flat" color="primary" size="small" class="px-3"  icon-pack="feather" icon="icon-refresh-cw" @click="refresh">Refresh</vs-button>
        </div>
      </div>
    </vx-card>

    <vx-card v-if="isDataInited">
      <vs-tabs alignment="fixed">
        <vs-tab label="Header">
          <TabHeader ref="tabHeader"/>
        </vs-tab>
        <vs-tab label="Media">
          <TabMedia ref="tabMedia"/>
        </vs-tab>
        <vs-tab label="Approvals">
          <TabApprovals ref="tabApprovals"/>
        </vs-tab>
        <vs-tab label="Recipients" v-if="tipePlatform === 'BROADCAST'">
          <TabRecipients ref="tabRecipients"/>
        </vs-tab>
        <vs-tab label="Reports" v-if="tipePlatform === 'ADVERTISING'">
          <TabReports ref="tabReports" @needRefresh="refresh"/>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import modulePengajuanMediaShow from '@/store/modules/marketing/pengajuan-media/pengajuan-media-show.store'
import PengajuanMediaRepository from '@/repositories/marketing/pengajuan-media-repository'

export default {
  name: 'PengajuanMediaShow',
  components: {
    TabHeader: () => import('@/views/pages/marketing/pengajuan-media/tabs/tab-header/TabHeader'),
    TabMedia: () => import('@/views/pages/marketing/pengajuan-media/tabs/tab-media/TabMedia'),
    TabRecipients: () => import('@/views/pages/marketing/pengajuan-media/tabs/tab-recipients/TabRecipients'),
    TabApprovals: () => import('@/views/pages/marketing/pengajuan-media/tabs/tab-approvals/TabApprovals'),
    TabReports: () => import('@/views/pages/marketing/pengajuan-media/tabs/tab-reports/TabReports')
  },
  mounted () {
    this.getInitData()
  },
  computed: {
    storePengajuanMediaShow () {
      return this.$store.state.marketing.pengajuanMediaShow
    },
    activeTab: {
      get () {
        return this.storePengajuanMediaShow.activeTab
      },
      set (value) {
        this.$store.commit('marketing/pengajuanMediaShow/SET_ACTIVE_TAB', value)
      }
    },
    initData: {
      get () {
        return this.storePengajuanMediaShow.initData
      },
      set (value) {
        this.$store.commit('marketing/pengajuanMediaShow/SET_INIT_DATA', value)
      }
    },
    tipePlatform () {
      return this.initData.header.tipe_platform
    },
    isCanPublish () {
      return this.initData.header.status_approval === 'DITERIMA' && this.initData.header.status === 'UNPUBLISHED'
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      isLoadingPublish: false
    }
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idPengajuanMedia = this.$route.params.idPengajuanMedia
      PengajuanMediaRepository.show(idPengajuanMedia)
        .then(response => {
          this.initData = response.data.data
          this.isDataInited = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    confirmPublish () {
      let message
      if (this.tipePlatform === 'BROADCAST') {
        message = 'Proses broadcast akan segera dimulai, proses ini mungkin memerlukan waktu. Lanjutkan broadcast?'
      } else {
        message = 'Status pengajuan media ini akan diubah menjadi published. Lanjutkan?'
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Konfirmasi',
        text: message,
        acceptText: 'Ya, Lanjutkan',
        cancelText: 'Batal',
        accept: () => { this.publish() }
      })
    },

    publish () {
      this.isLoadingPublish = true

      const idPengajuanMedia = this.$route.params.idPengajuanMedia
      PengajuanMediaRepository.publish(idPengajuanMedia)
        .then(response => {
          this.onPublishSuccess()
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingPublish = false
        })
    },

    onPublishSuccess () {
      let message
      if (this.tipePlatform === 'BROADCAST') {
        message = 'Proses broadcast sedang berlangsung, sistem akan memberikan notifikasi apabila broadcast telah selesai.'
      } else {
        message = 'Status berhasil diubah menjadi published.'
      }
      this.$vs.dialog({
        type: 'alert',
        color: 'success',
        title: 'Sukses',
        text: message,
        acceptText: 'OK'
      })
      this.refresh()
    },

    refresh () {
      this.getInitData()
    }
  },
  beforeCreate () {
    this.$store.registerModule(['marketing', 'pengajuanMediaShow'], modulePengajuanMediaShow)
  },
  beforeDestroy () {
    this.$store.commit('marketing/pengajuanMediaShow/RESET_STATE')
    this.$store.unregisterModule(['marketing', 'pengajuanMediaShow'])
  }
}
</script>

<style scoped lang="scss">
table.table-info {
  td {
    vertical-align: top;
    min-width: 140px;
    padding-bottom: .5rem;
    word-break: break-all;
  }
}
</style>
