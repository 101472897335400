import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/pengajuanMedia'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  show (idPengajuanMedia) {
    return httpClient.get(`${endpoint}/${idPengajuanMedia}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  publish (id) {
    return httpClient.post(`${endpoint}/${id}/publish`)
  },

  createReport (params) {
    return httpClient.post(`${endpoint}/reports`, params)
  },

  updateReport (params) {
    return httpClient.post(`${endpoint}/reports/update`, params)
  },

  deleteReport (id) {
    return httpClient.delete(`${endpoint}/reports/${id}`)
  }
}
